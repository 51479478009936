import React, { useEffect, useState } from "react";
import Faq from "react-faq-component";

import "../../components/Faq/Faq.css";

const data = {
  title: "Frequently Asked Questions",
  rows: [
    {
      title: "What are your tutoring rates?",
      content: `Our founder studied on a full-ride scholarship and, with limited financial resources, was able to put himself through school, owing a lot to his community. Now, our business operates by the motto "Education accessible for all." Book a lesson with us, and if we're a good fit, we'll ensure that budgeting for lessons is your last worry.`,
    },
    {
      title: "How can I track my child's progress?",
      content: `We keep you informed! Track your child's progress with regular reports from their tutor, view lesson plans and schedule weekly calls to discuss their learning journey.`,
    },
    {
      title:
        "Can we schedule a trial session to assess compatibility and teaching style?",
      content: `Certainly! We believe in the importance of finding the right fit for your child. You can absolutely schedule a trial session with one of our tutors to assess compatibility and teaching style. This allows you and your child to experience our tutoring approach firsthand and determine if it aligns with your expectations and learning goals. We're committed to providing the best educational experience, and the trial session is a great way to ensure a strong match between your child and the tutor.`,
    },
    {
      title:
        "What is your experience working with students who have learning disabilities?",
      content: `We empower all learners! Our tutors are experienced with various learning disabilities and ADHD, using their training to tailor tutoring aligned with your child's IEP/504 plan. 
            They utilize multisensory learning and one-on-one tutoring to cater to each student's unique needs. Our commitment is to provide an inclusive environment where every learner can thrive.`,
    },

    {
      title:
        "Do tutors provide assistance with homework or additional resources?",
      content: `Absolutely, our tutors are dedicated to supporting your child's academic success. They not only provide assistance with homework but also offer additional resources, such as practice materials, worksheets, and reference materials, to reinforce the learning process. We aim to be a valuable resource for your child's educational journey, helping them not only with their immediate assignments but also with building a strong foundation for the subject matter.
            `,
    },
  ],
};

export const FaqComponent = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="faq-style-wrapper">
        <Faq data={data} />
      </div>
    </div>
  );
};
