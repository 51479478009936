import { useEffect, useState } from 'react';

const Fetchstrapi = (url) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await fetch(url);
        const json = await res.json();

        if (Array.isArray(json.data)) {
          // If the response contains an array of data (blogs)
          setData(json.data);
        } else if (json.data) {
          // If the response contains a single data (blog)
          setData([json.data]);
        }

        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };
    fetchData();
  }, [url]);

  return { loading, error, data };
};

export default Fetchstrapi;
