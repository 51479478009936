import React, { useState, useEffect } from "react";
import Whyus1 from "../../images/WhyUs1_tutor.png";
import Whyus2 from "../../images/3whyus2.jpeg";
import WhyUs22 from "../../images/WhyUs2.png";
import Whyus4 from "../../images/WhyUs4_1.png";
import Whyus4_2 from "../../images/WhyUs4_2.png";
import Whyus3_tutors from "../../images/whyus3_tutors.png";

export const WhyUs = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="flex justify-center bg-themebgwhite w-auto h-auto flex lg:flex-row flex-col">
      <div className="flex justify-center items-center h-auto w-auto max-w-7xl">
        <div>
          {/* HORIZONTAL 1 */}
          <div className="bg-themebgwhite w-full lg:h-[700px] h-auto flex lg:flex-row flex-col">
            {/* PORTION 1 */}
            <div
              className={`lg:w-[620px] w-full h-full bg-themebgwhite flex flex-col ${
                isSmallScreen ? "text-center" : "text-center"
              }`}
            >
              <p className="font-Poppins font-bold lg:text-4xl text-3xl lg:pl-10 pl-5 lg:pr-0 pr-5 text-slate-800 first-letter lg:mt-44 mt-16 hover:scale-110 transition-transform duration-50">
                Who are <span className="text-sky-500">we?</span>
              </p>
              <br />
              <p className="font-Poppins lg:text-xl text-l lg:pl-10 pl-5 lg:pr-0 pr-5 text-slate-800 lg:mb-0 mb-5">
                We are a group of students and alumni – from some of the top
                universities around the globe – who noticed a problematic gap in
                the academic space: there was a lack of quality tutors, and a
                shortage of services to quickly and easily connect students with
                them.
                <br />
                <br />
                That is why we started North American Tutors: a one-stop
                platform where students can find and connect with their ideal
                tutor from a list of exceptionally competent individuals
                belonging to highly prestigious universities such as{" "}
                <span className="text-sky-500">MIT</span>,
                <span className="text-sky-500">Harvard</span>,{" "}
                <span className="text-sky-500">Oxford</span>, and more.
              </p>
            </div>

            {/* PORTION 2 */}
            <div className="flex-grow h-full bg-themebgwhite flex items-center justify-center">
              <img
                src={Whyus1}
                className="lg:h-2/3 lg:w-2/3 h-full w-full transform"
              />
            </div>
          </div>

          {/* HORIZONTAL 2 */}
          <div className="w-full lg:h-[600px] h-auto flex lg:flex-row flex-col lg:-mt-20 -mt-8">
            {/* PORTION 1 */}
            {!isSmallScreen && (
              <div className="flex-grow h-full bg-themebgwhite flex items-center justify-center lg:order-1 order-2">
                <img src={Whyus2} className="lg:h-2/3 lg:w-2/3 transform" />
              </div>
            )}

            {isSmallScreen && (
              <div className="flex-grow h-full bg-themebgwhite flex items-center justify-center lg:order-1 order-2">
                <img
                  src={Whyus3_tutors}
                  className="lg:h-2/3 lg:w-2/3 transform"
                />
              </div>
            )}

            {/* PORTION 2 */}
            <div
              className={`lg:w-[620px] w-full h-full bg-themebgwhite flex flex-col ${
                isSmallScreen ? "text-center" : "text-left"
              } lg:order-2 order-1`}
            >
              <p className="font-Poppins text-center font-bold lg:text-4xl text-3xl pr-5 mr-5 lg:pl-0 pl-5 text-slate-800 lg:mt-36 mt-24 hover:scale-110 transition-transform duration-50">
                What do we <span className="text-sky-500">offer?</span>
              </p>
              <br />
              <p className="font-Poppins lg:text-xl text-l pr-10 lg:pl-0 pl-8 text-slate-800 lg:mb-0 mb-5">
                <span className="font-bold">Elite 1-1 Subject Tutoring:</span>{" "}
                IB, AP, EdExcel, O’levels, A’Levels, K-12 and other high school
                programs.
                <br />
                <span className="font-bold">College Admissions:</span>{" "}
                Applications, Essays and interviews for top placements.
                <br />
                <span className="font-bold">Test Preparation:</span> SAT, ACT,
                GRE, MCAT etc.
                <br />
                <span className="font-bold">Homeschooling Support:</span>{" "}
                Curriculum, lesson planning and tutoring.
                <br />
                <span className="font-bold">And more:</span> Customizable
                solutions for all high school needs.
              </p>
            </div>
          </div>

          {/* HORIZONTAL 3 */}
          <div className="w-full lg:h-[600px] h-auto flex lg:flex-row flex-col lg:-mt-24 -mt-20">
            {/* PORTION 1 */}
            <div
              className={`lg:w-[620px] w-full h-full bg-themebgwhite flex flex-col ${
                isSmallScreen ? "text-center" : "text-left"
              } lg:pr-10 pr-5`}
            >
              <p className="font-Poppins font-bold lg:text-4xl text-3xl lg:pl-10 pl-5 lg:pr-0 pr-5 text-slate-800 lg:mt-44 mt-36 hover:scale-110 transition-transform duration-50">
                What makes us <span className="text-sky-500">different?</span>{" "}
              </p>
              <br />
              <p className="font-Poppins lg:text-xl text-l pr-10 lg:pl-0 pl-8 pr-3 text-slate-800 lg:mb-0 mb-5">
                <span className="font-bold">Elite Tutors:</span> All our tutors
                are exclusively from the top 20 universities/IVY league schools.
                <br />
                <span className="font-bold">Proven Track Record:</span>{" "}
                <span className="text-sky-500">92%</span> of our students
                increased their scores by{" "}
                <span className="text-sky-500">2 grade letters</span> or by at
                least <span className="text-sky-500">90 points</span> in the SAT
                <br />
                <span className="font-bold">
                  Comprehensive Support Services:
                </span>{" "}
                Not satisfied with your tutor? Your mentor will find an
                appropriate replacement.
                <br />
                <span className="font-bold">
                  Exclusive Study Guides and Resources:
                </span>{" "}
                Unreleased Test prep, college applications/essays of our tutors,
                or subject-specific guides. <br />
              </p>
            </div>
            {/* PORTION 2 */}
            {/* {!isSmallScreen && ( */}
            <div className="flex-grow h-full bg-themebgwhite flex items-center justify-center">
              {!isSmallScreen && (
                <img
                  src={Whyus3_tutors}
                  className="lg:h-2/3 lg:w-2/3 h-full w-full transform"
                />
              )}
            </div>

            {/* )} */}
          </div>
          {/* HORIZONTAL 4 */}
          <div className="w-full  h-auto flex flex-col lg:-mt-0 -mt-0">
            {/* PORTION 1 */}
            <div className="w-full bg-themebgwhite flex flex-col text-center lg:pr-5 pr-5">
              <p className="font-Poppins text-center font-bold lg:text-4xl text-3xl lg:pl-10 pl-5 lg:pr-0 pr-5 text-slate-800 lg:mt-0 mt-12 hover:scale-110 transition-transform duration-50">
                How does it <span className="text-sky-500">work?</span>
              </p>
            </div>
            {/* PORTION 2 */}
            {isSmallScreen ? (
              <div className="flex-grow bg-themebgwhite flex items-center justify-center">
                <img src={Whyus4_2} className=" h-full w-full transform" />
              </div>
            ) : (
              <div className="flex-grow bg-themebgwhite flex items-center justify-center">
                <img src={Whyus4} className=" h-full w-full transform" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
