import React, { useRef, useState, useEffect } from "react";
import { Button } from "@material-tailwind/react";
import { TypeAnimation } from "react-type-animation";
import { PopupButton } from "react-calendly";

import herobg from "../../images/herobg.png";
import "../../components/Hero/Hero.css";
import hero1 from "../../images/GreenBlonde.jpeg";
import hero2 from "../../images/HappyChinese.jpeg";
import hero3 from "../../images/SmilingTeen.jpeg";
import hero4 from "../../images/4.jpg";

export const Hero = ({ scrollToForm }) => {
  // State to hold window width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Effect to update window width value when window size changes
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Check if screen size is small (like a phone)
  const isPhoneScreen = windowWidth <= 768;

  return (
    <div className="flex justify-center">
      <div className="max-w-10xl w-full md:h-[500px] h-auto bg-themebanner flex flex-row justify-center md:mb-0 mb-10">
        <div className="bg-themebanner h-full md:min-w-[560px] md:w-[80%] text-center p-10 flex flex-col justify-center items-center">
          <p className="md:text-xl text-lg font-Poppins p-1 pl-2 t-16 font-bold">
            Unlocking Your Potential, One Lesson at a Time
          </p>
          <p className="md:text-6xl text-5xl font-Poppins p-1  ">Meet Your</p>
          <p className="md:text-6xl text-5xl font-Poppins p-1">Perfect</p>
          <p>
            <TypeAnimation
              sequence={[
                "Tutor",
                2000,
                "Mentor",
                2000,
                "Specialist",
                2000,
                "Guide",
                2000,
              ]}
              speed={10}
              deletionSpeed={50}
              repeat={Infinity}
              className="text-sky-500 text-center md:text-6xl text-5xl font-Poppins p-1"
            />
          </p>
          <p className="md:text-lg text-base font-Poppins p-1 ml-1">
            We Ensure
            <span className="md:text-lg text-base font-Poppins font-bold ml-1">
              "YOUR"
            </span>{" "}
            Learning is Personalised for
            <span className="text-lg font-Poppins font-bold ml-1">"YOU"</span>
          </p>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1px",
              width: isPhoneScreen ? "100%" : "50%",
              justifyContent: isPhoneScreen ? "center" : "flex-start",
              alignItems: "center", // Always center align items in this block
            }}
          >
            <Button
              onClick={scrollToForm}
              variant="gradient"
              size="sm"
              className="w-auto h-auto py-3 mt-5 bg-themedarkB font-Poppins font-bold rounded-lg hover:bg-themelightB"
            >
              <span>Schedule Your FREE Lesson!</span>
            </Button>

            {isPhoneScreen && (
              <Button
                variant="gradient"
                size="sm"
                className="w-auto h-auto py-3 mt-5 bg-themedarkB font-Poppins font-bold rounded-lg hover:bg-themelightB"
              >
                <a href="/tutors">Meet Our Tutors</a>
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  // return (
  //     <div className='flex justify-center'>
  //         <div className='justify-center max-w-10xl w-full md:h-[500px] h-auto bg-themebanner flex flex-row md:mb-0 mb-10 '>
  //             <div className='bg-themebanner h-full md:min-w-[560px] text-justify p-10 full_width'>
  //                 <p className='md:text-xl text-lg font-Poppins p-1 pl-2 t-16 font-bold'>Unlocking Your Potential, One Lesson at a Time</p>
  //                 <p className='md:text-6xl texl-5xl font-Poppins p-1'>Find Your</p>
  //                 <p className='md:text-6xl text-5xl font-Poppins p-1 no-line-break'>Perfect</p>
  //                 <p>
  //                     <TypeAnimation
  //                         sequence={[
  //                             "Tutor",
  //                             2000,
  //                             "Mentor",
  //                             2000,
  //                             "Specialist",
  //                             2000,
  //                             "Guide",
  //                             2000,
  //                         ]}
  //                         speed={10}
  //                         deletionSpeed={50}
  //                         repeat={Infinity}
  //                         className='text-sky-500 md:text-6xl text-5xl font-Poppins p-1'
  //                     />
  //                 </p>
  //                 <p className='md:text-lg text-base font-Poppins p-1 ml-1'>We Ensure
  //                     <span className='md:text-lg text-base font-Poppins font-bold ml-1'>"YOUR"</span> Learning is Personalised for<span className='text-lg font-Poppins font-bold ml-1'>"YOU"</span>
  //                 </p>

  //                 <div style={{
  //                     display: 'flex',
  //                     flexDirection: 'column',
  //                     gap: '1px',
  //                     width: isPhoneScreen ? '100%' : '50%',
  //                     justifyContent: isPhoneScreen ? 'right' : 'left',
  //                     alignItems: isPhoneScreen ? 'right' : 'left'
  //                 }}>
  //                     {/* <PopupButton
  //                         url="https://calendly.com/northamericantutors/call"
  //                         rootElement={document.getElementById("root")}
  //                         text="Schedule Your FREE Call!"
  //                         className="w-auto h-auto py-3 mt-5 bg-themedarkB font-Poppins font-bold rounded-lg hover:bg-themelightB text-white"
  //                     /> */}
  //                     <Button onClick={scrollToForm} variant="gradient" size="sm" className="w-auto h-auto py-3 mt-5  bg-themedarkB font-Poppins font-bold rounded-lg hover:bg-themelightB ">
  //     <span>Schedule Your FREE Call!</span>
  // </Button>

  //                     {isPhoneScreen && (
  //                         <Button variant="gradient" size="sm" className="w-auto h-auto py-3 mt-5 bg-themedarkB font-Poppins font-bold rounded-lg hover:bg-themelightB">
  //                             <a href="/tutors">Meet Our Tutors</a>
  //                         </Button>
  //                     )}
  //                 </div>
  //             </div>

  //             {/* <div className='md:h-5/6 md:w-1/2 h-auto w-auto flex flex-col justify-center items-center gap-4 self-center hide_4imgs'>
  //                 <div className='w-full h-1/2 flex flex-row justify-center items-center gap-1'>
  //                     <div className='h-full w-2/5 rounded-l-full hover:scale-110 transition-transform duration-50'>
  //                         <img className='h-full rounded-l-full object-cover' src={hero1}></img>
  //                     </div>
  //                     <div className='h-full w-2/5 rounded-r-full hover:scale-110 transition-transform duration-50'>
  //                         <img className='h-full rounded-r-full object-cover' src={hero2}></img>
  //                     </div>
  //                 </div>
  //                 <div className='w-full h-1/2 flex flex-row justify-center items-center gap-1'>
  //                     <div className='h-full w-2/5 rounded-l-full hover:scale-110 transition-transform duration-50'>
  //                         <img className='h-full rounded-l-full object-cover' src={hero3}></img>
  //                     </div>
  //                     <div className='h-full w-2/5 rounded-r-full hover:scale-110 transition-transform duration-50'>
  //                         <img className='h-full rounded-r-full object-cover' src={hero4}></img>
  //                     </div>
  //                 </div>
  //             </div> */}
  //         </div>
  //     </div>
  // );
};
