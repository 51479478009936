import React, { useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import "../../components/Stats/Stats.css";

export const Stats = () => {
  const [counterOn, SetCounterOn] = useState(false);
  return (
    <ScrollTrigger
      onEnter={() => SetCounterOn(true)}
      onExit={() => SetCounterOn(false)}
    >
      <div>
        <div className="w-full flex justify-center">
          <span className="text-4xl text-slate-800 font-Poppins font-bold md:mt-0 mt-12">
            Behind every number,{" "}
            <span className="text-sky-500">a story of trust.</span>
          </span>
        </div>

        <br />

        <div className="w-full h-auto flex gap-3 justify-center lg:flex-row flex-col items-center  p-2   ">
          <div className=" transition-transform duration-50 shadow1  bg-blue-300 md:h-[230px] h-auto md:w-[580px] w-auto flex flex-col items-start rounded-borders1 text-justify">
            <p className="text-slate-800 font-Poppins text-5xl pl-4 py-4">
              {counterOn && (
                <CountUp start={0} end={27} duration={2} delay={0} />
              )}
            </p>
            <p className="text-slate-800 font-Poppins text-4xl  pl-5 font-bold">
              Tutors
            </p>
            <p className="text-slate-800 font-Poppins text-xl pl-5 py-6 text-left">
              The number of DBS-checked, hand-picked, expert tutors ready to
              help you succeed
            </p>
          </div>

          <div className="  transition-transform duration-50  shadow2 bg-blue-500 md:h-[230px] h-auto md:w-[580px] w-auto flex flex-col items-start rounded-borders2 text-justify">
            <p className="text-slate-800 font-Poppins text-5xl pl-4 py-4">
              {counterOn && (
                <CountUp start={0} end={51} duration={2} delay={0} />
              )}
            </p>
            <p className="text-slate-800 font-Poppins text-4xl  pl-5 font-bold">
              Reviews
            </p>
            <p className="text-slate-800 font-Poppins text-xl pl-5 py-6 text-left">
              The number of 5-star reviews for our expert tutors from satisfied
              parents and students{" "}
            </p>
          </div>
        </div>

        <div className="w-full h-auto flex gap-3 justify-center lg:flex-row flex-col items-center p-2">
          <div className="   transition-transform duration-50  shadow3 lg:bg-blue-500 bg-blue-300 md:h-[230px] h-auto md:w-[580px] w-full flex flex-col items-start  rounded-borders3 text-justify ">
            <p className="text-slate-800 font-Poppins text-5xl pl-4 py-4">
              {counterOn && (
                <CountUp start={0} end={1327} duration={2} delay={0} />
              )}
            </p>
            <p className="text-slate-800 font-Poppins text-4xl  pl-5 font-bold">
              Lessons completed
            </p>
            <p className="text-slate-800 font-Poppins text-xl pl-5 py-6">
              The total number of lessons completed by our tutors
            </p>
          </div>

          <div className="  transition-transform duration-50  shadow4 lg:bg-blue-300 bg-blue-500 md:h-[230px] h-auto md:w-[580px] w-auto flex flex-col items-start rounded-borders4 text-justify">
            <p className="text-slate-800 font-Poppins text-5xl pl-4 py-4">
              {counterOn && (
                <CountUp start={0} end={212} duration={2} delay={0} />
              )}
            </p>
            <p className="text-slate-800 font-Poppins text-4xl  pl-5 font-bold">
              Students
            </p>
            <p className="text-slate-800 font-Poppins text-xl pl-5 py-6 text-left">
              The number of students that have ever been taught by one of our
              tutors
            </p>
          </div>
        </div>
      </div>
    </ScrollTrigger>
  );
};
