import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { Header } from "../../components/Header/Header";
import { Footer } from "../../components/Footer/Footer";
import { Form } from "../../components/Form/Form";
import Fetchstrapi from "../../StrapiDataFetch/useFetch";
import { Loading } from "../../components/Loading/Loading";
import "../../screens/Blogs/Blog.css";

export const Blogs = () => {
  const formRef = useRef(null);
  const scrollToForm = () => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const { loading, error, data } = Fetchstrapi(
    "https://nat-strapi.onrender.com/api/blogs?populate=*"
  );

  if (loading) {
    return (
      <div className="bg-themebgwhite h-full w-full">
        <Header />
        <Loading />
      </div>
    );
  }
  if (error) return <p>Error...</p>;

  if (!Array.isArray(data)) {
    return <p>No data available.</p>;
  }

  return (
    <div className="bg-themebgwhite">
      <Header scrollToForm={scrollToForm} />
      <br />
      <br />
      <br />
      <div className="w-full bg-[#f9f9f9] py-[10px]">
        <div className="max-w-[1240px] mx-auto">
          <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 ss:grid-cols-1 gap-8 px-4 text-black">
            {data.map((blog) => (
              <Link key={blog.id} to={`/blogcontent/${blog.id}`}>
                <div className="bg-white rounded-xl overflow-hidden drop-shadow-md md:h-[400px]">
                  <img
                    className="h-56 w-full object-cover"
                    src={blog.attributes.coverImg.data.attributes.url}
                    alt="Blog Cover"
                  />
                  <div className="p-8">
                    <h3 className="font-bold text-2xl my-1 font-Poppins">
                      {blog.attributes.blogTitle}
                    </h3>
                    <p className="text-gray-600 text-xl font-Poppins">
                      {blog.attributes.blogDesc}
                    </p>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Form formRef={formRef} />
      <Footer />
    </div>
  );
};
