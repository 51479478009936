import { Typography } from "@material-tailwind/react";
import logo from "../../images/Logo.png";
import instagram from "../../images/instagram.png";
import linkedIn from "../../images/linkedIn.png";
import facebook from "../../images/facebook.png";

export const Footer = () => {
  return (
    <footer className="w-full bg-themeblue p-4">
      <div className="flex flex-row flex-wrap items-center justify-center gap-y-6 gap-x-12 text-center md:justify-between">
        <img src={logo} alt="logo" className="w-18 h-16" />
        <ul className="flex flex-wrap items-center gap-y-2 gap-x-8">
          <li>
            <a href="https://www.linkedin.com/company/northamerican-tutors">
              <img
                src={linkedIn}
                alt="LinkedIn"
                className="w-15 h-8 hover:bg-blue-900 transition-colors"
              />
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/northamericantutors/">
              <img
                src={instagram}
                alt="Instagram"
                className="rounded-full w-15 h-10 hover:bg-pink-700 transition-colors"
              />
            </a>
          </li>
          <li>
            <a href="https://www.facebook.com/profile.php?id=61556534082623">
              <img
                src={facebook}
                alt="Facebook"
                className="w-15 h-8 hover:bg-blue-900 transition-colors"
              />
            </a>
          </li>
        </ul>
      </div>
      <hr className="my-8 border-blue-gray-50" />
      <Typography
        color="white"
        className="text-center font-Poppins font-normal"
      >
        &copy; 2024 NORTH AMERICAN TUTORS
      </Typography>
      <div className="flex flex-row flex-wrap items-center justify-center gap-y-6 gap-x-12 text-center md:justify-between">
        <div className="text-left font-Poppins font-small">
          <Typography color="white" className="font-bold">
            Address:
          </Typography>
          <Typography color="white" className="text-left">
            1728 S Robertson Blvd, Los Angeles, CA 90035
          </Typography>
          <Typography color="white" className="text-left">
            350 W Georgia St, Vancouver, BC V6B 6B3
          </Typography>
        </div>
        <div className="text-left font-Poppins font-small">
          <Typography color="white" className="font-bold">
            Hours:
          </Typography>
          <Typography color="white" className="text-right">
            Mon-Fri: 8 AM - 7 PM PST
          </Typography>
          <Typography color="white" className="text-right">
            Sat-Sun: 7 AM - 7 PM PST
          </Typography>
        </div>
      </div>
    </footer>
  );
};
