import React from 'react';
import { ClimbingBoxLoader } from 'react-spinners'; // You can use any loading animation library
import "../../components/Loading/Loading.css"

export const Loading = () => {
  return (
    <div className="loading-container">
    <div className="centered-content">
      <ClimbingBoxLoader size={25} color="#007BFF" />
      <br/>
      <p className='font-Poppins text-2xl font-bold'>Loading...</p>
    </div>
  </div>
  );
};

