import React, { useState, useEffect, useRef } from "react";
import { Header } from "../../components/Header/Header";
import { Footer } from "../../components/Footer/Footer";
import { Form } from "../../components/Form/Form";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Loading } from "../../components/Loading/Loading";

const ContentRenderer = ({ content }) => {
  return content.map((block, index) => {
    switch (block.type) {
      case "paragraph":
        return (
          <p key={index} style={{ marginBottom: "1em", lineHeight: "1.6" }}>
            {block.children.map((textNode, nodeIndex) =>
              textNode.bold ? (
                <b key={nodeIndex}>{textNode.text}</b>
              ) : (
                <span key={nodeIndex}>{textNode.text}</span>
              )
            )}
          </p>
        );
      case "heading":
        return (
          <h2
            key={index}
            style={{
              fontSize: "1.5em",
              fontWeight: "bold",
            }}
          >
            {block.children.map((textNode, nodeIndex) =>
              textNode.bold ? (
                <b key={nodeIndex}>{textNode.text}</b>
              ) : (
                <span key={nodeIndex}>{textNode.text}</span>
              )
            )}
          </h2>
        );
      default:
        return null;
    }
  });
};

export const BlogContent = () => {
  const formRef = useRef(null);
  const scrollToForm = () => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const { id } = useParams();
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.get(
          `https://nat-strapi.onrender.com/api/blogs/${id}?populate=*`
        );
        setBlog(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchBlog();
  }, [id]);

  if (loading) {
    return (
      <div className="bg-themebgwhite h-full w-full">
        <Header />
        <Loading />
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-themebgwhite h-full w-full">
        <Header />
        <div className="text-center mt-20">
          An error occurred: {error.message}
        </div>
      </div>
    );
  }

  const {
    coverImg,
    blogTitle,
    blogContent,
    authorImg,
    authorName,
    authorDesc,
  } = blog.data.attributes;

  return (
    <div className="bg-themebgwhite">
      <Header scrollToForm={scrollToForm} />
      <div className="w-full pb-10 bg-[#f9f9f9] md:-mt-14 mt-8">
        <div className="max-w-[1240px] mx-auto">
          <div className="grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1 ss:grid-cols-1 md:gap-x-8 sm:gap-y-8 ss:gap-y-8 px-4 sm:pt-20 md:mt-0 ss:pt-20 text-black">
            <div className="col-span-2">
              <img
                className="h-56 w-full object-cover"
                src={coverImg.data.attributes.url}
                alt="Blog Cover"
              />
              <h1 className="font-bold text-2xl my-1 pt-5 font-Farro">
                {blogTitle}
              </h1>
              <div className="pt-15 font-Poppins leading-8">
                <ContentRenderer content={blogContent} />
              </div>
            </div>
            <div className="items-center w-full bg-white rounded-xl drop-shadow-md py-5 max-h-[250px]">
              <div>
                <img
                  className="p-2 w-32 h-32 rounded-full mx-auto object-cover"
                  src={authorImg.data[0].attributes.url}
                  alt="Author"
                />
                <h1 className="font-bold font-Cabin text-2xl text-center text-gray-900 pt-3">
                  {authorName}
                </h1>
                <p className="text-center font-Poppins text-gray-900 font-medium">
                  {authorDesc}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Form formRef={formRef} />
      <Footer />
    </div>
  );
};
