import {

  Button,
} from "@material-tailwind/react";

import { Fragment, useState } from 'react'
import { Combobox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import formImage from "../../images/formImage.png"
import { InlineWidget, PopupWidget} from "react-calendly";



import React from 'react';
import { Country } from "country-state-city"
import "../Form/Form.css"
import axios from "axios"
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const Form = ({ formRef }) => {

  let countryData = Country.getAllCountries();

  const [selected, setSelected] = useState({name:'Select Your Country'})
  const [query, setQuery] = useState('')
  const filteredCountries = query === ''
    ? countryData
    : countryData.filter((c) =>
      c.name
        .toLowerCase()
        .replace(/\s+/g, '')
        .includes(query.toLowerCase().replace(/\s+/g, ''))
    );

  const [activeButton, setActiveButton] = useState(null);

  const [classification, Setclassification] = useState("");
  const [fullname, setFullname] = useState('');
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [email, setEmail] = useState('');
  const [phonenumber, setPhonenumber] = useState('');
  const [details, setDetails] = useState('');


  const button1click = () => {
    setActiveButton(1);
    Setclassification("Parent")

  }


  const button2click = () => {
    setActiveButton(2);
    Setclassification("Student")

  }
  function isPhoneScreen() {
    const windowWidth = window.innerWidth;
    // Define your threshold for phone screen width, for example, less than 600px
    const phoneScreenWidth = 600;
    return windowWidth <= phoneScreenWidth;
  }
  

  // const calendlyInLine = () => {
  //   // Calculate the height and width based on the screen size
  //   const windowHeight = window.innerHeight;
  //   const windowWidth = window.innerWidth;
    
  //   // Set the height and width for the Calendly inline widget
  //   const height = windowHeight * 0.84; // 70% of the window height
  //   const width = windowWidth * 0.76; // 80% of the window width
    
  //   return (
  //     <div className="App" style={{ height: `${height}px`, width: `${width}px` }}>
  //       <InlineWidget url="https://calendly.com/northamericantutors/call" styles={{ height: '100%', width: '100%' }} />
  //     </div>
  //   );
  // };

  const calendlyPopUp = () => {
    return (
      <div className="App" style={{ position: 'relative', top: '50px', left: '50px', width: '400px', height: '500px' }}>
        <PopupWidget
          url="https://calendly.com/northamericantutors/call"
          rootElement={document.getElementById("root")}
          text={<span style={{whiteSpace: 'pre-line', fontFamily: 'Poppins', fontSize: '15px'}}>{"Schedule Your\nFree Call"}</span>}
          textColor="#ffffff"
          color="#023E8A"
        />
      </div>
    );
  };
  
  



  const sendtoDB = () => {
    const currentDate = new Date().toLocaleString();
    console.log(classification);
    console.log(fullname);
    console.log(selectedCountry);
    console.log(phonenumber);
    console.log(email);
    console.log(details);

    axios({
      method: "post",
      url: "https://nat-backend.vercel.app/customerdetails",
      data: {
        classification,
        fullname,
        selectedCountry,
        phonenumber,
        email,
        details,
        currentDate,
      },
    })
      .then((res) => {
        toast.success('Success Message', {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });

          setActiveButton(null);
          Setclassification("");
          setFullname('');
          setSelectedCountry(null);
          setSelected({name:'Select Your Country'})
          setEmail('');
          setPhonenumber('');
          setDetails('');
       
      })
      .catch((err) => {
        toast.error(err.response.data.error, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
      
      });




  }



  return (


    <div ref={formRef} class=" relative w-full h-auto flex  justify-center  mb-10 ">
      
      <img
        src={formImage}
        className="lg:h-[700px] h-[900px]  w-full object-cover"
      />

     <div className="absolute z-1 mt-5  w-[95%] lg:w-[80%] flex flex-col justify-center items-center">
        <p className="font-bold md:text-4xl text-2xl text-center text-white font-Poppins tracking-wider">WE'LL MAKE SURE         <span style={{color: "#FFC501"}} className="ml-1 font-bold md:text-4xl text-2xl">"YOU"</span>

          <span className="font-bold md:text-4xl text-2xl text-white font-Poppins"> SUCCEED</span></p>
        <p className="font-bold md:text-2xl text-lg text-white font-Poppins mb-6 mt-1 tracking-wider ">CONNECT WITH US TODAY FOR FREE</p>

        <div class="bg-white flex flex-col lg:w-[45%] md:w-[85%] w-[95%] h-auto p-4 rounded-md">

          <div className="flex items-center justify-center space-x-0 lg:space-x-5 flex-col lg:flex-row space-y-5 lg:space-y-0">

            <div
            className={`bg-white lg:w-[45%] w-[90%] py-3 text-lg flex justify-center items-center font-Poppins
            text-blue-gray-500 border border-slate-400 cursor-pointer rounded-md ${activeButton === 1 ? 'active' : ''}`}
              onClick={button1click}>
              <span>I am a Parent</span>

            </div>


            <div className={`bg-white lg:w-[45%] w-[90%] py-3 text-lg flex justify-center items-center font-Poppins
          text-blue-gray-500 border border-slate-400 cursor-pointer rounded-md ${activeButton === 2 ? 'active' : ''}`}
             onClick={button2click}>
              <span>I am a Student</span>

            </div>

          </div>
          <br />

          <div className="px-3 flex lg:flex-row flex-col items-center justify-between">

            <div className="flex flex-col lg:w-[48%] w-[100%] ">
              <p className="text-black text-left font-Poppins">Full name</p>
              <input
                className="p-3 w-full border-2 rounded-md"
                placeholder="Your Name"
                value={fullname}
                onChange={(e) => setFullname(e.target.value)}
              />
            </div>




            <div className="flex flex-col lg:w-[48%] w-[100%]">
              <p className="text-black text-left font-Poppins">Country</p>
              <div className="relative flex justify-center ">
                <Combobox
                  value={selected}
                  onChange={(selectedOption) => {
                    setSelected(selectedOption);
                    setSelectedCountry(selectedOption ? selectedOption.name : null);;
                  }}
                >
                  <div className="relative w-full cursor-default overflow-hidden border-2 rounded-md bg-white text-left  focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                    <Combobox.Input
                      className="w-full border-none p-3.5  text-sm leading-5 text-gray-900 "
                      displayValue={(country) => country.name}
                      onChange={(event) => setQuery(event.target.value)}
                    />
                    <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </Combobox.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    afterLeave={() => setQuery('')}
                  >
                    <Combobox.Options className="absolute z-10 mt-2 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {filteredCountries.length === 0 && query !== '' ? (
                        <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                          Nothing found.
                        </div>
                      ) : (
                        filteredCountries.map((country) => (
                          <Combobox.Option
                            key={country.id}
                            className={({ active }) =>
                              `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-slate-800 text-white' : 'text-gray-900'
                              }`
                            }
                            value={country}
                          >
                            {({ selected, active }) => (
                              <>
                                <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                                  {country.name}
                                </span>
                                {selected ? (
                                  <span className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-teal-600'}`}>
                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Combobox.Option>
                        ))
                      )}
                    </Combobox.Options>
                  </Transition>
                </Combobox>
              </div>

            </div>

          </div>



          <br />

          <div className="px-3 flex lg:flex-row flex-col items-center justify-between">

            <div className="flex flex-col lg:w-[48%] w-[100%] ">
              <p className="text-black text-left font-Poppins">Phone (Country Code)</p>
              <input
                className="p-3 w-full border-2 rounded-md"
                placeholder="Enter your phone"
                value={phonenumber}
                onChange={(e) => setPhonenumber(e.target.value)}
              />
            </div>

            <div className="flex flex-col lg:w-[48%] w-[100%] ">
              <p className="text-black text-left font-Poppins">Email</p>
              <input
              type="email"
                className="p-3 w-full border-2 rounded-md"
                placeholder="Your Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

          </div>

          <br />




          <div className="flex flex-col lg:w-full w-[100%]   ">
            <p className="text-black text-center font-Poppins">Where do you want to succeed? Tell us more!</p>
            <input
              className="ml-3 p-4 w-[95%] border-[1px] border-black rounded-sm"
              placeholder=""
              value={details}
              onChange={(e) => setDetails(e.target.value)}
            />
          </div>




          <br />
          <Button variant="gradient" className=" bg-themelightB self-end w-1/3 py-2 font-Poppins font-bold text-lg 
      text-blue-gray-500 border border-slate-400" onClick={sendtoDB}>
            <span className="text-white">Submit</span>

          </Button>

        </div>

        <ToastContainer/>


{/* <!-- Calendly PopUpWidget widget begin --> */}
{/* <div style={{ marginTop: isPhoneScreen() ? 0 : '-3rem' }}> */}
{/* < div style={{ position: 'relative', top: '50px', left: '20px' }}>
  {calendlyPopUp()}
</div> */}

{/* {/* <!-- Calendly PopUpWidget widget end --> */}
      </div>
 


    </div>


  );
}