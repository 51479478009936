import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Analytics } from "@vercel/analytics/react";
import { Home, Tutors, AboutUs, Blogs, BlogContent } from "./screens/index";
import { clarity } from "react-microsoft-clarity";
import ReactPixel from "react-facebook-pixel";

// Initialize Microsoft Clarity
clarity.init("nltlirhnim");

// Initialize Meta Pixel
const pixelOptions = {
  autoConfig: true, // Enable automatic event detection
  debug: false, // Disable debug mode
};
ReactPixel.init("3819720181605870", null, pixelOptions);

// Track the first page view
const TrackPageView = () => {
  const location = useLocation();

  useEffect(() => {
    ReactPixel.pageView(); // Track page view on route change
  }, [location]);

  return null;
};

const RootApp = () => (
  <Router>
    <TrackPageView />
    <Routes>
      <Route index element={<App />} />
      <Route exact path="/home" element={<Home />} />
      <Route exact path="/tutors" element={<Tutors />} />
      <Route exact path="/aboutUs" element={<AboutUs />} />
      <Route exact path="/blogs" element={<Blogs />} />
      <Route path="/blogcontent/:id" element={<BlogContent />} />
    </Routes>
    <Analytics />
  </Router>
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RootApp />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
