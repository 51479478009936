import React, { useRef, useState, useEffect } from 'react';
import { Header } from "../../components/Header/Header";
import { Footer } from "../../components/Footer/Footer";
import { Form } from "../../components/Form/Form"
import { Loading } from "../../components/Loading/Loading"
import "../Tutors/Tutors.css"
import {
  Button,

} from "@material-tailwind/react";
import t1 from "../../images/t1.jpg"
import t4 from "../../images/t4.jpg"
import axios from 'axios';

export const Tutors = () => {


  const formRef = useRef(null);
  const scrollToForm = () => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };



  const [isLoading, setIsLoading] = useState(true);
  const [expandedTutors, setExpandedTutors] = useState({});
  const [tutorsToDisplay, setTutorsToDisplay] = useState(3);

  const toggleDescription = (tutorId) => {
    // setShowFullDescription(!showFullDescription);

    setExpandedTutors(prevState => ({
      ...prevState,
      [tutorId]: !prevState[tutorId],
    }));
  };


  function truncateDescription(description, words) {
    const wordArray = description.split(' ');
    if (wordArray.length > words) {
      const truncatedText = wordArray.slice(0, words).join(' ');
      return `${truncatedText} ...`;
    }
    return description;
  }


  
  const loadMoreTutors = () => {
    setTutorsToDisplay((prevTutors) => prevTutors + 3); // Increase the number of tutors to display by 2
  };

  const [alltutors, SetAllTutors] = useState([])



  const UserData = () => {

    axios({

      method: "get",
      url: "https://nat-backend.vercel.app/getalltutors"

    })

    .then((res) => {
      let sortedData = res.data.sort((a, b) => a.orderNumber - b.orderNumber);
      SetAllTutors(sortedData);
      setIsLoading(false);
  })


      .catch((err) => {

        alert(err.response.data.error);

      })

  }

  useEffect(() => {
    UserData();
  }, []);







  return (
    <div>

      <Header scrollToForm={scrollToForm} />
      <br />
      {isLoading ? (
        <Loading />
      ) : (
        // Display tutor data when isLoading is false
        <div>





      <div className=' w-full  flex flex-col '>

        <div className='lg:text-justify text-left lg:w-4/5 w-full custom-breakpoint1'>
          <h1 className='text-5xl font-Cabin  lg:px-48 px-20'>Tutor Spotlight</h1>
          <br />
          <p className='text-xl font-Poppins lg:px-48 px-20'>Our tutors have all studied at the the world's best universities, achieved top grades, and have extensive tutoring experience.</p>
          <br />
          <p className='text-xl font-Poppins font-bold  lg:px-48 px-20'>We'll find the perfect tutor for you based on your requirements!</p>
          <br />
        </div>

      </div>


      <br />
      <br />



      {/* CARD */}

     
      {alltutors.slice(0,tutorsToDisplay).map((tutor) => (


        <div className=' mb-12 w-full h-auto flex justify-center'>

          <div className='bg-white custom-breakpoint1  h-auto w-3/4 rounded-lg border-2 border-solid border-stone-200 flex lg:flex-row flex-col
 '>

            <div className={`lg:w-[25%] custom-breakpoint  w-auto h-full rounded-tl-lg rounded-bl-lg
 ${expandedTutors[tutor._id] ? 'flex justify-center h-full' : ''}`}>
              <img className={`h-full w-full  self-center  
    ${expandedTutors[tutor._id] ? ' lg:rounded-lg lg:h-[250px] h-full lg:ml-5' : 'rounded-tl-lg rounded-bl-lg'}`} src={tutor.image}></img>
            </div>


            <div className={`lg:w-1/2 w-full h-auto  `}>
              <p className='font-bold font-Cabin text-2xl py-4 px-5'>{tutor.name}</p>
              <p className=' font-Cabin text-xl px-5'>{tutor.qualification}</p>

              <p className={`font-Poppins text-md px-5 py-3 ${expandedTutors[tutor._id] ? 'slide-down' : ''}`}>
                {expandedTutors[tutor._id] ? tutor.desc : truncateDescription(tutor.desc, 35)}
              </p>
            </div>

            <div className=' h-full lg:w-[30%] w-full border-l-2 border-solid border-stone-200 flex flex-col justify-between'>

              <div className='w-full  h-auto flex flex-wrap'>
                <p className='font-Poppins font-bold text-md p-3 mt-1 flex flex-wrap space-x-2  '>Teaches: &nbsp;
                  {tutor.subjects && tutor.subjects.length > 0 ? (
                    tutor.subjects.map((subject, index) => (
                      <span key={index} className='font-Poppins text-blue-900 font-bold bg-slate-200 rounded-md mb-2 p-1'>
                        {subject}
                      </span>
                    ))
                  ) : (
                    <span>No subjects listed</span>
                  )}
                </p>
              </div>

              <div className=' w-full flex flex-col  h-auto border-t-2 border-solid border-stone-200 '>

                <Button onClick={() => toggleDescription(tutor._id)} variant="gradient" size="sm" className="w-full h-auto py-3  bg-white text-black
    font-Poppins font-bold rounded-none hover:bg-slate-100">
                  <span>{expandedTutors[tutor._id] ? "See Less" : "See More"}</span>
                </Button>





                <Button onClick={scrollToForm} variant="gradient" size="sm" className="w-full h-auto py-3 bg-themedarkB
    font-Poppins font-bold rounded-tl-none rounded-tr-none rounded-bl-none rounded-br-lg hover:bg-themelightB ">

                  <span>Hire a Tutor</span>

                </Button>




              </div>

            </div>


          </div>

        </div>



      )


      )}

            {tutorsToDisplay < alltutors.length && (
            <div className='flex justify-center w-full'>
              <Button
                variant='gradient'
                size='sm'
                className='w-auto h-auto py-3 -mt-6 bg-themedarkB font-Cabin font-bold rounded-lg hover:bg-themelightB self-center mb-3'
                onClick={loadMoreTutors}
              >
                <span>Load More</span>
              </Button>
              <br/>
            <br/>
            <br/>
            <br/>
            </div>
          )}


      {/* CARD */}





      <Form formRef={formRef} />

     

      <Footer />
      </div>
     )}
    </div>

  );

}

